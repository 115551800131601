import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  withRouter,
  Switch,
} from "react-router-dom";
import { Provider, connect } from "redux-zero/react";
import ReactMarkdown from "react-markdown";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import fetch from "isomorphic-fetch";

import * as api from "./api";
import { store, actions } from "./state";

// Images
import closeIcon from "./images/x.png";
import spinner from "./images/spinner.svg";

const Loading = () => <img src={spinner} width="80" alt="Cargando..." />;

const isIPad = () => navigator.userAgent.match(/iPad/i) != null;

const Logo = connect(({ mainConfig }) => ({
  url: mainConfig.logoImageUrl || "",
}))(({ url }) => {
  const style = {
    backgroundImage: `url(${url})`,
  };
  return <div className="logo" style={style} />;
});

const scrollAware = (InnerComponent) =>
  class ScrollAwareComponent extends Component {
    state = {
      scrollY: 0,
      innerWidth: 0,
      innerHeight: 0,
    };
    componentWillMount() {
      window.addEventListener("scroll", this.onScroll);
    }
    componentDidMount() {
      const { scrollY, innerWidth, innerHeight } = window;
      this.setState({ scrollY, innerWidth, innerHeight });
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.onScroll);
    }
    onScroll = (event) => {
      const { scrollY, innerWidth } = window;
      this.setState({ scrollY, innerWidth });
    };
    render() {
      return <InnerComponent {...this.props} {...this.state} />;
    }
  };

let CarouselDots = ({ dots, current, onClick, innerHeight, requireFix }) => {
  const dotsStyle = () => {
    const el = document.getElementsByClassName("project-images");
    //Dinamic positioning of dots only on projects images.
    if (el.length) {
      const { top, height: elemHeight } = el[0].getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const footerHeight = 60;
      // Initially: windowHeight - (headerHeight + footerHeight) + headerHeight - top;
      const dotsPosition = windowHeight - footerHeight - top;
      return { top: Math.min(dotsPosition, elemHeight) - 48 };
    } else {
      return { top: requireFix && isIPad() ? innerHeight - 48 : "auto" };
    }
  };

  const ipadFixClass = requireFix && isIPad() ? "ipad-fix" : "";
  return (
    <div className={`carousel-dots ${ipadFixClass}`} style={dotsStyle()}>
      {Array.from({ length: dots }).map((i, idx) => (
        <div
          key={idx}
          className={`dot ${idx === current ? "selected" : ""}`}
          onClick={() => onClick(idx)}
        />
      ))}
    </div>
  );
};

CarouselDots = scrollAware(CarouselDots);

class ImageCarousel extends Component {
  state = {
    imageNumber: 0,
  };
  componentDidMount() {
    this.interval = setInterval(this.nextImageNumber, 8000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  nextImageNumber = () => {
    const { images = [] } = this.props;
    const imageCount = images.length;
    const { imageNumber } = this.state;
    const nextNumber = imageNumber + 1 >= imageCount ? 0 : imageNumber + 1;
    this.setState({ imageNumber: nextNumber });
  };
  prevImageNumber = () => {
    const { images = [] } = this.props;
    const imageCount = images.length;
    const { imageNumber } = this.state;
    const nextNumber = imageNumber - 1 < 0 ? imageCount - 1 : imageNumber - 1;
    this.setState({ imageNumber: nextNumber });
  };
  onDotClick = (number) => {
    this.setState({ imageNumber: number });
  };
  // TODO: Preload images. Show spinner in image loading progress.
  render() {
    const {
      containerClassName,
      images = [],
      innerWidth,
      children,
      requireDotsFix,
    } = this.props;
    const { imageNumber } = this.state;
    const style = {
      backgroundImage: images[imageNumber]
        ? `url(${images[imageNumber]})`
        : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=)",
    };
    return (
      <div className={`carousel ${containerClassName || ""}`} style={style}>
        {
          // Force image preload
          images.map((imgSrc, idx) => (
            <img key={idx} src={imgSrc} width="0" height="0" alt={`p${idx}`} />
          ))
        }
        {children}
        {innerWidth >= 768 && (
          <CarouselDots
            dots={images.length}
            current={imageNumber}
            onClick={this.onDotClick}
            requireFix={requireDotsFix}
          />
        )}
        {innerWidth < 768 && (
          <div className="arrow left" onClick={this.prevImageNumber}>
            {"<"}
          </div>
        )}
        {innerWidth < 768 && (
          <div className="arrow right" onClick={this.nextImageNumber}>
            {">"}
          </div>
        )}
      </div>
    );
  }
}

ImageCarousel = scrollAware(ImageCarousel);

class HomePage extends Component {
  state = {
    images: [],
    loading: true,
  };
  componentDidMount() {
    api
      .getMainPageImages()
      .then((images) => this.setState({ images, loading: false }));
  }
  render() {
    const { images } = this.state;
    const {
      projectsLinkText,
      usLinkText,
      contactLinkText,
    } = this.props.mainConfig;
    return (
      <ImageCarousel
        images={images}
        containerClassName="home-page"
        requireDotsFix={true}
      >
        <nav>
          <ul>
            <li className="logo">
              <Logo />
            </li>
            <li>
              <Link to="/proyectos/todos">{projectsLinkText}</Link>
            </li>
            <li>
              <Link to="/nosotros">{usLinkText}</Link>
            </li>
            <li>
              <Link to="/contacto">{contactLinkText}</Link>
            </li>
          </ul>
        </nav>
      </ImageCarousel>
    );
  }
}
HomePage = connect((state) => state, actions)(HomePage);

const scrollBy = (distance, duration) => {
  var initialY = window.scrollY;
  var y = initialY + distance;
  var baseY = (initialY + y) * 0.5;
  var difference = initialY - baseY;
  var startTime = performance.now();

  function step() {
    var normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    const targetY = baseY + difference * Math.cos(normalizedTime * Math.PI);
    if (!isNaN(targetY)) {
      window.scrollTo(0, targetY);
    }
    if (normalizedTime < 1) {
      window.requestAnimationFrame(step);
    }
  }
  window.requestAnimationFrame(step);
};

class MainMenu extends Component {
  state = {
    subItems: [],
  };
  onScroll = () => {};
  componentDidMount() {
    const { location } = this.props;
    this.fetchSubItems(location.pathname);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.fetchSubItems(nextProps.location.pathname);
    }
  }
  fetchSubItems = (pathname) => {
    api.getMainMenuSubItems(pathname).then((subItems) => {
      this.setState({ subItems });
    });
  };
  getAffixItemIds = () => {
    const { subItems } = this.state;
    return subItems.reduce((itemsWithId, item) => {
      const targetId = item.to.split("#")[1];
      return targetId ? [...itemsWithId, targetId] : itemsWithId;
    }, []);
  };
  getCurrentAffixItemId = () => {
    return this.getAffixItemIds().reduce((affixId, itemId, idx, items) => {
      if (affixId) {
        return affixId;
      }
      const target = document.getElementById(itemId);
      if (target) {
        if (idx + 1 === items.length) {
          // If reach the last item, always mark it as active.
          return itemId;
        }
        const { y, top } = target.getBoundingClientRect();
        const yValue = isIPad() ? top : y;
        if (yValue > 0) {
          return itemId;
        }
      }
      return "";
    }, "");
  };
  render() {
    const { location } = this.props;
    if (location.pathname === "/") {
      return (
        <div className="main-menu inverse">
          <Logo />
        </div>
      );
    }
    const { subItems } = this.state;
    const currentAffixItemId = this.getCurrentAffixItemId();
    const {
      projectsLinkText,
      usLinkText,
      contactLinkText,
    } = this.props.mainConfig;
    return (
      <div className="main-menu">
        <NavLink to="/">
          <Logo />
        </NavLink>
        <div className="links">
          <div className="main-items">
            <li>
              <NavLink to="/proyectos/todos" activeClassName="active">
                {projectsLinkText}
              </NavLink>
            </li>
            <li>
              <NavLink to="/nosotros" activeClassName="active">
                {usLinkText}
              </NavLink>
            </li>
            <li>
              <NavLink to="/contacto" activeClassName="active">
                {contactLinkText}
              </NavLink>
            </li>
          </div>
          <div className="sub-items">
            {subItems.map((item) => (
              <li key={item.name}>
                {item.to.indexOf("#") !== -1 ? (
                  <a
                    className={
                      currentAffixItemId === item.to.split("#")[1]
                        ? "active"
                        : ""
                    }
                    href={item.to}
                    onClick={() => {
                      const targetId = item.to.split("#")[1];
                      const target = document.getElementById(targetId);
                      if (target) {
                        const targetRect = target.getBoundingClientRect();
                        const y = isIPad() ? targetRect.top : targetRect.y;
                        scrollBy(y - 140, 1000); // -140 to compensate padding
                      }
                    }}
                  >
                    {item.text}
                  </a>
                ) : (
                  <NavLink
                    exact={item.exact}
                    to={item.to}
                    activeClassName="active"
                  >
                    {item.text}
                  </NavLink>
                )}
              </li>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
MainMenu = connect(
  (state) => state,
  actions
)(withRouter(scrollAware(MainMenu)));

class ProjectThumbnail extends Component {
  render() {
    const { project } = this.props;
    const to = `/proyectos/${project.types[0].toLowerCase()}/${project.slug}`;
    const style = {
      backgroundImage: `url(${project.thumbnailImage})`,
    };
    return (
      <div className="project-thumbnail">
        <Link to={to} className="background" style={style}>
          <div className="info">
            <div className="title">
              <span>{project.name}</span>
            </div>
            <div className="subtitle">
              <span>{project.types && project.types[0]}</span>
            </div>
            <div className="subtitle">
              <span>{project.year}</span>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

class Projects extends Component {
  state = {
    projects: [],
    loading: true,
  };
  componentDidMount() {
    const { match } = this.props;
    this.fetchProjects(match.params.filter || "all");
  }
  componentWillReceiveProps(nextProps) {
    // const filter = this.props.match.params.filter || 'all';
    // const nextFilter = nextProps.match.params.filter || 'all';
    // if (filter !== nextFilter) {
    //   this.fetchProjects(nextFilter);
    // }
  }
  fetchProjects = (filter) => {
    const { projects } = this.state;
    if (projects.length === 0) {
      api
        .getProjects()
        .then((projects) => this.setState({ projects, loading: false }));
    }
  };
  getVisibleProjects = () => {
    const filter = this.props.match.params.filter || "todos";
    const { projects } = this.state;
    return filter === "todos"
      ? projects
      : projects.filter(
          (project) =>
            project.types.map((t) => t.toLowerCase()).indexOf(filter) !== -1
        );
  };
  render() {
    const { loading } = this.state;
    const projects = this.getVisibleProjects();

    if (loading) {
      return <Loading />;
    }

    const projectListClass = `projects-list ${isIPad() ? "ipad-fix" : ""}`;

    if (isIPad()) {
      return (
        <div className={projectListClass}>
          {projects.map((project) => (
            <ProjectThumbnail key={project.id} project={project} />
          ))}
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
        </div>
      );
    }
    return (
      <div className={projectListClass}>
        {projects.map((project) => (
          <ProjectThumbnail key={project.id} project={project} />
        ))}
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
      </div>
    );
  }
}

class Project extends Component {
  state = {
    project: {},
    loading: true,
  };
  componentDidMount() {
    const slug = this.props.match.params.projectSlug;
    api
      .getProject(slug)
      .then((project) => this.setState({ project, loading: false }));
  }
  render() {
    const { project, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="project">
        <ImageCarousel
          images={project.images}
          containerClassName="project-images"
        />
        <div className="name">{project.name}</div>
        <div className="project-info">
          <div className="row">
            <span className="title">Tipo</span>
            <span className="value">{project.types && project.types[0]}</span>
          </div>
          <div className="row">
            <span className="title">Descripción</span>
            <span className="value">{project.location}</span>
          </div>
          <div className="row">
            <span className="title">Año</span>
            <span className="value">{project.year}</span>
          </div>
          <div className="row">
            <span className="title">Cliente</span>
            <span className="value">{project.client}</span>
          </div>
        </div>
      </div>
    );
  }
}

class AboutUs extends Component {
  state = {
    usPost: {},
    steps: [],
    clients: { images: [] },
  };
  componentWillMount() {
    api.getPost("nosotros").then((usPost) => this.setState({ usPost }));
    api.getHowSteps().then((steps) => this.setState({ steps }));
    api.getClients().then((clients) => this.setState({ clients }));
  }
  render() {
    const { usPost, steps = [], clients = {} } = this.state;
    const { usTitle1, usTitle2, usTitle3 } = this.props;
    const usImageStyle = {
      backgroundImage: `url(${usPost.image})`,
    };
    const { images: logos = [] } = clients;
    return (
      <div className="about-us-page">
        <h1 id="nosotros">{usTitle1}</h1>
        <section className="us" id="us">
          <div className="us-text">
            <ReactMarkdown source={usPost.content || ""} />
          </div>
          <div className="us-image" style={usImageStyle} />
        </section>
        <br />
        <br />
        <h1 id="como">{usTitle2}</h1>
        <section className="how">
          {steps.map(({ image, number, title, text }) => (
            <div className="how-item" key={number}>
              <div className="image">
                <img src={image} alt="" />
              </div>
              <div className="number">
                <span>{number}</span>
              </div>
              <div className="title">
                <span>{title}</span>
              </div>
              <div className="text">
                <span>{text}</span>
              </div>
            </div>
          ))}
        </section>

        <br />
        <br />
        <h1 id="clientes">{usTitle3}</h1>
        <section className="clients">
          {logos.map((url, id) => (
            <div className="client-logo" key={id}>
              <img src={url} alt="" />
            </div>
          ))}
          <div className="client-logo spacer" />
          <div className="client-logo spacer" />
          <div className="client-logo spacer" />
          <div className="client-logo spacer" />
        </section>
      </div>
    );
  }
}
const aboutUsStateToProps = (state) => {
  const { usTitle1 = "", usTitle2 = "", usTitle3 = "" } = state.mainConfig;
  return {
    usTitle1,
    usTitle2,
    usTitle3,
  };
};
AboutUs = connect(aboutUsStateToProps, actions)(AboutUs);

const PostPreview = ({ post }) => (
  <a
    href={`/blog/${post.slug}`}
    className={`post-preview ${post.pinned ? "pinned" : ""}`}
  >
    <div
      className="main-image"
      style={{ backgroundImage: `url(${post.image})` }}
      alt=""
    />
    <h2 className="date">
      {format(new Date(post.date), "MMMM D", { locale: es })}
    </h2>
    <h2 className="title">{post.title}</h2>
  </a>
);

class Blog extends Component {
  state = {
    posts: [],
    loading: true,
  };
  componentDidMount() {
    api.getPosts().then((posts) => this.setState({ posts, loading: false }));
    setTimeout(() => {
      this.props.showSubcriptionForm();
    }, 10000);
  }
  render() {
    const { posts, loading } = this.state;
    const pinnedPosts = posts.filter((p) => p.pinned);
    const notPinnedPosts = posts.filter((p) => !p.pinned);

    if (loading) {
      return <Loading />;
    }

    const postsListClass = `posts-list ${isIPad() ? "ipad-fix" : ""}`;

    return (
      <div className={postsListClass}>
        {pinnedPosts.length > 0 && (
          <div className="spacer full">
            <p />
          </div>
        )}
        {pinnedPosts.length === 0 && (
          <div className="spacer">
            <p />
          </div>
        )}
        {pinnedPosts.length === 0 && (
          <div className="spacer">
            <p />
          </div>
        )}

        {pinnedPosts.map((post) => (
          <PostPreview key={post.id} post={post} />
        ))}
        {notPinnedPosts.map((post) => (
          <PostPreview key={post.id} post={post} />
        ))}
      </div>
    );
  }
}

class BlogPost extends Component {
  state = {
    post: {},
    loading: true,
  };
  componentDidMount() {
    const slug = this.props.match.params.postSlug;
    api.getPost(slug).then((post) => this.setState({ post, loading: false }));
  }
  render() {
    const { post, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (!post) {
      return (
        <div>
          <div>Contenido no encontrado.</div>
          <a href="/blog">Volver al blog</a>
        </div>
      );
    }

    if (!post) {
      return (
        <div>
          <div>Contenido no encontrado.</div>
          <a href="/blog">Volver al blog</a>
        </div>
      );
    }

    return (
      <div className="blogPost">
        <div className="date">
          {format(new Date(post.date), "MMMM D", { locale: es })}
        </div>
        <div className="title">{post.title}</div>
        <div className="content">
          {post.youtubeVideoId && (
            <div className="videowrapper">
              <iframe
                title="Post video"
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${post.youtubeVideoId}`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}
          <ReactMarkdown source={post.content} />
        </div>
      </div>
    );
  }
}

// Correo de destino: angeladelgado@objetodiseno.com
class ContactForm extends Component {
  onSubmitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const elements = event.target.elements;
    const details = {
      name: elements.name.value,
      phone: elements.phone.value,
      email: elements.email.value,
      message: elements.message.value,
    };
    document.getElementById("submitBtn").setAttribute("disabled", "true");
    fetch("/sendcontactform", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ details }),
    })
      .then((result) => {
        form.reset();
        alert("Mensaje enviado. Gracias por contactarnos.");
      })
      .catch(() => {
        alert(
          "Ocurrió un error al enviar la información, por favor inténtelo de nuevo más tarde."
        );
        document.getElementById("submitBtn").removeAttribute("disabled");
      });
  };
  render() {
    return (
      <form className="contactForm grid pad" onSubmit={this.onSubmitForm}>
        <div className="col-5">
          <div className="grid">
            <div className="col-6">
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="name">Nombre</label>
                </div>
                <div className="col-12">
                  <input name="name" type="text" required />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="phone">Teléfono</label>
                </div>
                <div className="col-12">
                  <input name="phone" type="text" required />
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="email">Correo electrónico</label>
                </div>
                <div className="col-12">
                  <input name="email" type="email" required />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="grid">
            <div className="col-12">
              <label htmlFor="message">Mensaje</label>
            </div>
            <div className="col-12">
              <textarea name="message" required />
            </div>
          </div>
        </div>

        <div className="col-1">
          <div className="grid">
            <div className="col-12">
              <label>&nbsp;</label>
            </div>
            <div className="col-12">
              <input id="submitBtn" type="submit" value="Enviar" />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

class SmallContactForm extends Component {
  onSubmitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const elements = event.target.elements;
    const details = {
      name: elements.name.value,
      phone: elements.phone.value,
      email: elements.email.value,
      message: elements.message.value,
    };
    document.getElementById("submitBtn").setAttribute("disabled", "true");
    fetch("/sendcontactform", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ details }),
    })
      .then((result) => {
        form.reset();
        alert("Mensaje enviado. Gracias por contactarnos.");
      })
      .catch(() => {
        alert(
          "Ocurrió un error al enviar la información, por favor inténtelo de nuevo más tarde."
        );
        document.getElementById("submitBtn").removeAttribute("disabled");
      });
  };
  render() {
    return (
      <form className="contactForm small">
        <div className="field">
          <label htmlFor="name">Nombre completo</label>
          <input name="name" type="text" required />
        </div>
        <div className="field">
          <label htmlFor="phone">Teléfono</label>
          <input name="phone" type="text" required />
        </div>
        <div className="field">
          <label htmlFor="email">Correo electrónico</label>
          <input name="email" type="email" required />
        </div>
        <div className="field">
          <label htmlFor="message">Mensaje</label>
          <textarea name="message" rows="5" required />
        </div>
        <div className="field">
          <label>&nbsp;</label>
          <input type="submit" value="Enviar" />
        </div>
      </form>
    );
  }
}

class GoogleMapsIframe extends Component {
  render() {
    const { address } = this.props;
    return address ? (
      <iframe
        title="Contact map"
        src={`https://www.google.com/maps?z=18&q=${address}&output=embed`}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    ) : null;
  }
}

class Contact extends Component {
  state = {
    contactInfo: [],
  };
  componentWillMount() {
    api.getContactInfo().then((info) => this.setState({ contactInfo: info }));
  }
  render() {
    const { contactInfo = [] } = this.state;
    const mainContact = contactInfo[0] || {};
    const { address: mapAddress } = mainContact;
    return (
      <div className="contactPage">
        <div className="mapContainer">
          <div id="mapDiv">
            <GoogleMapsIframe address={mapAddress} />
          </div>
          <div className="branchOfficesInfo">
            {contactInfo.map(({ name, address, phoneNumber, email }) => (
              <div className="info" key={name}>
                <div className="title">{name}</div>
                <div className="line">
                  <i className="icon-location" />
                  {address}
                </div>
                <div className="line">
                  <i className="icon-phone" />
                  {phoneNumber}
                </div>
                <div className="line">
                  <i className="icon-mail-alt" />
                  <a href="mailto:contacto@objetodiseno.com">{email}</a>
                </div>
              </div>
            ))}
          </div>

          <ContactForm />
        </div>
        <SmallContactForm />
        <br />
        <Link to="/blog/legales">
          POLÍTICA DE PROTECCIÓN DE DATOS / LEGALES
        </Link>
      </div>
    );
  }
}

const IconButton = ({ iconName, href }) => (
  <a href={href} className={`iconButton social-${iconName}`} target="_blank" />
);

// const ScrollDownButton = ({ hidden }) => (
//   <div className={`scrollDownButton ${hidden ? 'hidden' : ''}`}>
//     <div className="dot" />
//   </div>
// );

const bottomMenuStateToProps = (state) => {
  const {
    facebookUrl = "",
    linkedInUrl = "",
    instagramUrl = "",
    mainPageBottomText = "",
  } = state.mainConfig;
  return { facebookUrl, linkedInUrl, instagramUrl, mainPageBottomText };
};
const BottomMenu = connect(
  bottomMenuStateToProps,
  actions
)(
  scrollAware(
    withRouter(
      ({
        inverse,
        backButton,
        match,
        history,
        innerHeight,
        facebookUrl,
        linkedInUrl,
        instagramUrl,
        mainPageBottomText,
      }) => (
        <div className={`bottomMenu ${inverse ? "inverse" : ""}`}>
          <div className="socialIconsStrip">
            {facebookUrl && <IconButton iconName="fb" href={facebookUrl} />}
            {linkedInUrl && <IconButton iconName="lin" href={linkedInUrl} />}
            {instagramUrl && <IconButton iconName="ig" href={instagramUrl} />}
          </div>
          <div className="bottomMid">
            {mainPageBottomText && inverse && (
              <p className="address">{mainPageBottomText}</p>
            )}
            {!inverse && backButton && (
              <i
                className="icon-down"
                onClick={() => scrollBy(innerHeight, 600)}
              />
            )}
          </div>
          <div>
            <Link
              className={`back-button ${backButton ? "" : "hidden"}`}
              onClick={(event) => {
                event.preventDefault();
                history.goBack();
              }}
              to="#"
            >
              <i className="icon-left" />
              <span>Atrás</span>
            </Link>
          </div>
        </div>
      )
    )
  )
);

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

class SubscriptionForm extends Component {
  state = { email: "", error: false };
  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  submit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const error = !validateEmail(email);
    if (!error) {
      fetch("/sendsubscriptionform", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ email }),
      })
        .then((result) => {
          alert("Solicitud de suscripción enviada.");
          this.props.hideSubcriptionForm();
        })
        .catch(() => {
          alert("Ocurrió un error, por favor inténtelo de nuevo más tarde.");
          this.props.hideSubcriptionForm();
        });
    } else {
      this.setState({ error });
    }
  };
  render() {
    const { email, error } = this.state;
    const { visible, hideSubcriptionForm } = this.props;
    return (
      <div className={`full-screen-overlay ${visible ? "visible" : ""}`}>
        <form className="content" onSubmit={this.submit}>
          <img
            alt="Close icon"
            className="close-icon"
            src={closeIcon}
            onClick={hideSubcriptionForm}
          />
          <p>Suscríbete a nuestro blog: noticias, diseño, actualidad.</p>
          <div className="email">
            <div>
              <label>Correo electrónico</label>
            </div>
            <div>
              <input
                className={error ? "error" : ""}
                type="text"
                value={email}
                onChange={this.onEmailChange}
              />
            </div>
          </div>
          <button type="submit">Suscribirse</button>
        </form>
      </div>
    );
  }
}

class App extends Component {
  state = {
    subscriptionFormVisible: false,
  };
  componentDidMount() {
    api.getMainConfig().then(this.props.setMainConfig);
  }
  showSubcriptionForm = () => {
    this.setState({ subscriptionFormVisible: true });
  };
  hideSubcriptionForm = () => {
    this.setState({ subscriptionFormVisible: false });
  };
  render() {
    const { subscriptionFormVisible } = this.state;
    return (
      <div className="App">
        <SubscriptionForm
          visible={subscriptionFormVisible}
          hideSubcriptionForm={this.hideSubcriptionForm}
        />
        <Route path="/" component={MainMenu} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/proyectos" component={Projects} />
        <Route exact path="/proyectos/:filter" component={Projects} />
        <Route
          exact
          path="/proyectos/:filter/:projectSlug"
          component={Project}
        />
        <Route exact path="/nosotros" component={AboutUs} />
        <Route
          exact
          path="/blog"
          render={() => <Blog showSubcriptionForm={this.showSubcriptionForm} />}
        />
        <Route exact path="/blog/:postSlug" component={BlogPost} />
        <Route exact path="/contacto" component={Contact} />
        <Switch>
          <Route
            exact
            path="/proyectos/:filter/:projectSlug"
            render={() => <BottomMenu backButton />}
          />
          <Route
            exact
            path="/blog/:postSlug"
            render={() => <BottomMenu backButton />}
          />
          <Route
            path="/"
            render={({ match }) => <BottomMenu inverse={match.isExact} />}
          />
        </Switch>
      </div>
    );
  }
}
App = connect((state) => state, actions)(App);

class Root extends Component {
  render() {
    return (
      <div>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </div>
    );
  }
}

export default Root;
