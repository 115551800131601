const projectsSubItems = [
  {
    name: 'all',
    text: 'Todos',
    to: '/proyectos/todos',
    exact: true,
  },
  {
    name: 'comercial',
    text: 'Comercial',
    to: '/proyectos/comercial',
    exact: false,
  },
  {
    name: 'corporate',
    text: 'Corporativo',
    to: '/proyectos/corporativo',
    exact: false,
  },
  {
    name: 'residential',
    text: 'Residencial',
    to: '/proyectos/residencial',
    exact: false,
  },
];

const posts = [
  {
    id: '1',
    slug: 'test-post-1',
    date: 1506111003458,
    title: 'ABSTRACT: LA SERIE DE NETFLIX QUE NINGÚN CREATIVO DEBERÍA PERDERSE',
    image: '/images/blog/1.jpg',
    youtubeVideoId: 'XUwBtlWDGP8',
    content:
      'Ya lo dijo **Paul Rand**: “todo es diseño, ¡todo!” y eso queda claro al final de los 8 episodios de **Abstract: the Art of Desing**, la serie que **Netflix** estrenó este año.\n Los protagonistas de cada capítulo son algunos de los mejores creativos en sus áreas y durante cada episodio es posible conocer de cerca sus procesos creativos y aquellas caracteristicas esenciales de su trabajo que los han llevado a la cima en sus carreras profesionales.\n' +
      '\n **Episodio 1. Christoph Niemann: Ilustración (Alemania)** Tapas para **The New Yorker**. Bosquejos en Instagram. El ilustrador Christoph Niemann juega con la abstracción, mientras explora la interactividad y duda de la autenticidad.\n' +
      '\n **Episodio 2. Tinker Hatfield: Diseño de calzado (Estados Unidos)** Tinker Hatfield aprovechó su pasado en la **arquitectura** y el deporte para diseñar los calzados más revolucionarios, como la icónica serie Air Jordan de Nike.\n' +
      '\n **Episodio 3. Es Devlin: Diseño de escenarios (Inglaterra)** Es Devlin usa luces, imágenes, esculturas y hasta lluvia para evocar escenarios demoledores.\n' +
      '\n **Episodio 4. Bjarke Ingels: Arquitectura (Dinamarca)** Bjarke Ingels une funcionalidad, fantasía y sustentabilidad en diseños "utópicamente pragmáticos", como una planta de energía ecológica coronada con una pista de esquí.\n' +
      '\n **Episodio 5. Ralph Gilles: Diseño automotriz (Estados Unidos)** Ralph Gilles está al volante y planea renovar los diseños de Fiat Chrysler con deportivos eternamente elegantes y camionetas eléctricas autónomas.\n' +
      '\n **Episodio 6. Paula Scher: Diseño gráfico (Estados Unidos)** Paula Scher pinta con palabras y desarrolla el lenguaje visual de marcas que luego se convierten en íconos e instituciones de todo el mundo.\n' +
      '\n **Episodio 7. Platon: Fotografía (Grecia)** Los impasibles retratos de Platon capturan el alma de líderes mundiales y de gente común y corriente. Una sesión con Colin Powell revela partes de su proceso.  \n' +
      '\n **Episodio 8. Ilse Crawford: Diseño de interiores (Inglaterra)** lse Crawford crea espacios y objetos para hoteles de lujo, Ikea y otros clientes con una idea fundamental: estimular y pacificar los sentidos al mismo tiempo.En redes sociales algunos usuarios ya han manifestado que esperan una **segunda temporada de Abstract**, esta serie documental creada por Scott Dadich. \n' +
      '\n __bold__ *italic* *__bold italic__* \n' +
      '\n ![4](//images.contentful.com/mbn3kd450frs/4ix0xsnrkscu0USOqAYe6U/f6ca561fa4f4274606572afde709c738/4.jpg) \n' +
      '\n ***Imágenes: Netflix***',
    listed: true,
    pinned: true,
  },
  {
    id: '2',
    slug: 'test-post-2',
    date: 1506291103458,
    title: 'Titular lorem ipsum dolor sit amet eleifend tellus 1',
    image: 'http://via.placeholder.com/450x302/',
    content:
      'Lorem ipsum dolor sit amet, consecte- tur adipiscing elit. Sed felis uis odio, uis vestibulum sed dictum quis, molestie quis odio. Nulla massa non justo rhon- cus, et pretium odio rutrum.',
    listed: true,
  },
  {
    id: '3',
    slug: 'test-post-3',
    date: 1506391203458,
    title: 'Titular lorem ipsum dolor sit amet eleifend tellus 2',
    image: 'http://via.placeholder.com/450x300/',
    content:
      'Lorem ipsum dolor sit amet, consecte- tur adipiscing elit. Sed felis uis odio, uis vestibulum sed dictum quis, molestie quis odio. Nulla massa non justo rhon- cus, et pretium odio rutrum.',
    listed: true,
  },
  {
    id: '5',
    slug: 'test-post-1',
    date: 1506691303458,
    title: 'ABSTRACT: LA SERIE DE NETFLIX QUE NINGÚN CREATIVO DEBERÍA PERDERSE',
    image: '/images/blog/1.jpg',
    youtubeVideoId: 'lJIrF4YjHfQ',
    content: '',
    listed: true,
  },
  {
    id: '4',
    slug: 'test-post-4',
    date: 1506491403458,
    title: 'Titular lorem ipsum dolor sit amet eleifend tellus 3',
    image: 'http://via.placeholder.com/450x301/',
    content:
      '# Title \nLorem ipsum dolor sit amet, consecte- tur adipiscing elit. Sed felis uis odio, uis vestibulum sed dictum quis, molestie quis odio. Nulla massa non justo rhon- cus, et pretium odio rutrum.',
    listed: true,
  },
  {
    id: '6',
    slug: 'test-post-1',
    date: 1506991503458,
    title: 'ABSTRACT: LA SERIE DE NETFLIX QUE NINGÚN CREATIVO DEBERÍA PERDERSE',
    image: '/images/blog/1.jpg',
    youtubeVideoId: 'lJIrF4YjHfQ',
    content: '',
    listed: true,
  },
  {
    id: '7',
    slug: 'legales',
    date: 150689603458,
    title: 'Titular lorem ipsum dolor sit amet eleifend tellus',
    image: 'http://via.placeholder.com/450x302/',
    content:
      'Lorem ipsum dolor sit amet, consecte- tur adipiscing elit. Sed felis uis odio, uis vestibulum sed dictum quis, molestie quis odio. Nulla massa non justo rhon- cus, et pretium odio rutrum.',
    listed: false,
  },
  {
    id: '8',
    slug: 'nosotros',
    date: 150689803458,
    title: 'Nosotros',
    image: 'http://via.placeholder.com/800x600/',
    content: `
Somos una compañía colombiana dedicada a la gerencia y desarrollo de proyectos de arquitectura y mobiliario con 10 años de experiencia en el sector.

Contamos con un equipo de profesionales que brinda asesoría personalizada a través de análisis, diseños y estudios técnicos.

Creamos soluciones innovadoras y estéticas que logran espacios confortables y provocan la interacción de las personas con su entorno. Nuestros clientes reconocen la calidad y el cumplimiento en sus proyectos gracias al control de procesos que ejercemos en nuestra planta de producción equipada con maquinaria especializada.
    `,
    listed: false,
  },
];

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const isDevelopment = () => {
  const { hostname } = window.location;
  return hostname === 'localhost';
};

const firebaseDevOrigin =
  'http://localhost:5000/web-dela-design/us-central1/app';
const getServerUrl = () => {
  const { origin } = window.location;
  return isDevelopment() ? firebaseDevOrigin : origin;
};

export const fetchServerData = (dataSrc) => () => {
  const serverUrl = getServerUrl();
  console.log(`fetch ${serverUrl}/${dataSrc}`);
  return fetch(`${serverUrl}/${dataSrc}`)
    .then((response) => response.json())
    .catch((err) => {
      console.error('************ error ************');
      console.error(err);
    });
};

export const getMainConfig = fetchServerData('datamainconfig');

export const getHowSteps = fetchServerData('datahowsteps');

export const getClients = fetchServerData('dataclients');

export const getContactInfo = fetchServerData('datacontactinfo');

export const getMainPageImages = fetchServerData('datamainimages');

export const getProjects = fetchServerData('dataprojects');

export const getProject = (slug) => fetchServerData(`dataproject/${slug}`)();

export const getMainMenuSubItems = (pathname) => {
  if (pathname.startsWith('/proyectos')) {
    return Promise.resolve(projectsSubItems);
  } else if (pathname.startsWith('/nosotros') || pathname.startsWith('/blog')) {
    return getMainConfig().then(({ usTitle1, usTitle2, usTitle3 }) => {
      return [
        {
          name: 'us',
          text: usTitle1,
          to: '/nosotros#nosotros',
          exact: true,
        },
        {
          name: 'how',
          text: usTitle2,
          to: '/nosotros#como',
          exact: true,
        },
        {
          name: 'clients',
          text: usTitle3,
          to: '/nosotros#clientes',
          exact: true,
        },
        {
          name: 'blog',
          text: 'Blog',
          to: '/blog',
          exact: false,
        },
      ];
    });
  }
  return Promise.resolve([]);
};

export const getPosts = () => {
  const { hostname, origin } = window.location;
  if (hostname === 'localhost') {
    return wait(1000)
      .then(() => posts.filter((p) => p.listed))
      .then((posts) => posts.sort((a, b) => a.date < b.date));
  }
  return fetch(`${origin}/dataposts`)
    .then((response) => response.json())
    .then((posts) => posts.sort((a, b) => a.date < b.date))
    .catch(console.error);
};

export const getPost = (slug) => {
  const { hostname, origin } = window.location;
  if (hostname === 'localhost') {
    return wait(1000).then(() => posts.find((post) => post.slug === slug));
  }
  return fetch(`${origin}/datapost/${slug}`)
    .then((response) => response.json())
    .catch(console.error);
};
