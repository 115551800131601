import createStore from 'redux-zero';

const initialState = {
  mainConfig: {},
};
const store = createStore(initialState);

const actions = store => ({
  setMainConfig: (state, mainConfig = {}) => {
    return { mainConfig };
  },
});

export { store, actions };
